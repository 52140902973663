<template>
  <h2>1. Descrierea jocului</h2>
  <p>
    Jocul Highlight Games Soccerbet este o simulare software unică a rezumatelor selectate aleatoriu din meciuri pentru
    a crea oportunități de joc.
  </p>
  <h2>2. Descrierea și durata jocului</h2>
  <p>
    O singură rundă a unui joc de pariuri electronice durează în medie 5 minute, constând în anunțurile partidei pentru
    săptămâna de joc respectivă, afișare și rezultate.
  </p>
  <p>
    O selecție de evenimente de fotbal evidențiate este independentă statistic, aleatorie și imprevizibilă și generată
    de Generatorul de numere aleatorii - RNG.
  </p>
  <h2>3. Piețele de pariuri</h2>
  <h3>3.1 Goluri exacte</h3>
  <p>
    Semnificația parierii Numărul exact de goluri este aceea că un număr exact de goluri trebuie marcate într-un singur
    meci. Deci, această variație de pariere înseamnă că trebuie să preziceți un număr exact de goluri, în ciuda
    rezultatului final al unui meci întreg.
  </p>
  <h3>3.2 Goluri exacte ale echipei gazdă</h3>
  <p>Un pariu pe numărul exact de goluri pe care echipa gazdă le va înscrie în meci.</p>
  <h3>3.3 Goluri exacte ale echipei oaspete</h3>
  <p>Un pariu pe numărul exact de goluri pe care echipa oaspete le va înscrie în meci.</p>
  <h3>3.4 Ambele echipe să înscrie</h3>
  <p>
    Pentru a câștiga un pariu BTTS, ambele echipe trebuie să înscrie cel puțin un gol, făcând din orice rezultat de la
    1-1 în sus, un pariu câștigător. Pariul „Ambele echipe să înscrie” oferă pariorilor șansa de a profita de astfel de
    evenimente, pentru a obține cote profitabile, când doar una dintre cele două echipe a reușit să înscrie.
  </p>
  <h3>3.5 Scor corect</h3>
  <p>
    Scorul corect înseamnă să pariați pe ceea ce credeți că va fi rezultatul final al unui meci de fotbal. La un maxim
    de 6 goluri într-un joc există 28 de rezultate posibile.
  </p>
  <h3>3.6 Șansă dublă</h3>
  <p>
    Un pariu cu șansă dublă vă permite să acoperiți două dintre cele trei rezultate posibile într-un meci de fotbal cu
    un singur pariu. Echipa gazdă și remiză - Pariul dvs. este câștigător dacă echipa gazdă câștigă sau face remiză.
    Echipa oaspete și remiză - Pariul dvs. este câștigător dacă echipa oaspete câștigă sau face remiză.
  </p>
  <h3>3.7 Rezultatul meciului (1X2)</h3>
  <p>Un pariu pe victoria echipei gazdă (1), victoria echipei oaspete (2) sau meciul se încheie cu o remiză (X).</p>
  <h3>3.8 Rezultatul meciului și ambele echipe să înscrie (1X2 + BTTS)</h3>
  <p>
    Un pariu pe rezultatul meciului (1 este victoria echipei gazdă, X este remiză, 2 este victoria echipei oaspete) și
    dacă ambele echipe înscriu sau nu.
  </p>
  <ul>
    <li>1 + Gol - Echipa gazdă va câștiga și ambele echipe vor înscrie cel puțin un gol [de ex., 3-1].</li>
    <li>1 + Fără gol - Echipa gazdă va câștiga și este singura echipă care înscrie un gol [de ex., 1-0].</li>
    <li>X + Gol - Pariu pe o remiză și scorul ambelor echipe. [de ex., 1-1, 2-2].</li>
    <li>X + Fără gol – Pariu pe o remiză fără ca echipele să înscrie. [numai 0-0].</li>
    <li>2 + Gol - Echipa oaspete va câștiga și ambele echipe vor înscrie cel puțin un gol [de ex., 1-2, 1-3].</li>
    <li>2 + Fără gol - Echipa oaspete câștigă și este singura echipă care înscrie goluri [de ex., 0-1, 0-2].</li>
  </ul>
  <p></p>

  <h3>3.9 Rezultatul meciului și numărul de goluri peste/sub 1,5</h3>
  <p>Un pariu pe rezultatul meciului de la 1X2 și numărul total de goluri să fie sub sau peste 1,5 goluri.</p>
  <h3>3.10 Rezultatul meciului și numărul de goluri peste/sub 2,5</h3>
  <p>Un pariu pe rezultatul meciului de la 1X2 și numărul total de goluri să fie sub sau peste 2,5 goluri.</p>
  <h3>3.11 Total goluri (1 până la 2, 1 până la 3, 1 până la 4, 2 până la 3, 3 până la 4)</h3>
  <p>
    Un pariu că meciul va avea un total de 1 până la 2, 1 până la 3, 1 până la 4 sau 2 până la 4 goluri ca rezultat
    final. Acesta este un pariu de tip DA sau NU.
  </p>
  <h3>3.12 Peste/Sub 1,5 goluri ale echipei gazdă</h3>
  <p>Un pariu că echipa gazdă va înscrie mai mult sau mai puțin de 1,5 goluri în meci.</p>
  <h3>3.13 Peste/Sub 1,5 goluri ale echipei oaspete</h3>
  <p>Un pariu că echipa oaspete va înscrie mai mult sau mai puțin de 1,5 goluri în meci.</p>
  <h3>3.14 Peste/Sub 1,5, 2,5, 3,5, 4,5 goluri totale</h3>
  <p>
    Un pariu pe numărul total de goluri marcate de ambele echipe să fie sub sau peste 1,5, 2,5, 3,5 sau 4,5 în meci.
  </p>
</template>
